
  export const style = {
    api: {
      apiProduction: "https://hubsocial.prd.appnoz.com.br/",
      newApiProduction: "null",
      samlUrl: null,
      nameProfile: "hubsocial",
      hostHeader: "hubsocial",
    },

    icons: {
      loginIcon: "https://s3.amazonaws.com/timcoo-geral/Logos/logoHubSocial%403x.png",
      menuIcon: localStorage.getItem("menuIcon") || "https://timcoo-geral.s3.amazonaws.com/Logos/secondary_logo_hub.png",
      browserLogo: "https://s3.amazonaws.com/timcoo-geral/Logos/logoHubSocial%403x.png",
    },

    shareAttributes: {
      shareImage: "https://s3.amazonaws.com/timcoo-geral/Logos/logoHubSocial%403x.png",
      shareTitle: "Noz - Plataforma de comunicação",
      shareDescription: "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
    },

    names: {
      nameApp: localStorage.getItem("nameApp") || "hubsocial",
      nameScore: "Nozes",
      nameStore: "HUB SOCIAL STORE",
      nameAcademy: localStorage.getItem("nameAcademy") || "Academy",
      nameTeam: localStorage.getItem("nameTeam") || "Time",
      nameCalendar: localStorage.getItem("nameCalendar") || "Calendário",
      nameTools: localStorage.getItem("nameTools") || "Ferramentas",
    },

    colors: {
      primaryColor: localStorage.getItem("primaryColor") || "#1A734F",
      secondaryColor: localStorage.getItem("secondaryColor") || "#064966",
      tertiaryColor: "",
    },

    variables: {
      gamificationEnabled: true,
      child_profiles: [],
      hasPrivacyPolicies: false,
      externalUse: false,
      customClient: false,
    },
  };
